import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useContext, useEffect, useRef } from "react";
import { FaArrowRight } from "react-icons/fa6";
import CartContext from "../../context/cart-context";
import { RadioButton, RadioButtonGroup, Checkbox } from "../forms";

const QuoteSummaryInstallation = ({
  emitSubmit,
  emitForm,
  emitGoToTab,
  hasLiners,
}) => {
  const { checkoutInfo, setCheckoutInfo } = useContext(CartContext);
  const installFormRef = useRef();

  useEffect(() => {
    if (installFormRef.current) {
      emitForm(installFormRef);
    }
  }, [installFormRef]);

  const handleSubmit = () => {
    emitSubmit();
  };

  return (
    <>
      <Formik
        innerRef={installFormRef}
        initialValues={{
          shippingAddress: checkoutInfo.shippingAddress
            ? checkoutInfo.shippingAddress
            : "",
          shippingAddress2: checkoutInfo.shippingAddress2
            ? checkoutInfo.shippingAddress2
            : "",
          shippingCity: checkoutInfo.shippingCity
            ? checkoutInfo.shippingCity
            : "",
          shippingState: process.env.BILLING_STATE,
          wellCleaning:
            typeof checkoutInfo.wellCleaning !== "undefined"
              ? checkoutInfo.wellCleaning
              : false,
          whichWells: checkoutInfo.whichWells || "",
          whichWellsNotes: checkoutInfo.whichWellsNotes || "",
          billingNotes: checkoutInfo.billingNotes,
        }}
        validate={(values) => {
          let errors = {};
          if (!values.shippingAddress) {
            errors.shippingAddress = "Required";
          }
          if (!values.shippingCity) {
            errors.shippingCity = "Required";
          }
          if (!values.shippingState) {
            errors.shippingState = "Required";
          }
          if (!values.shippingState) {
            errors.shippingState = "Required";
          }
          if (!values.whichWells) {
            errors.whichWells = "Required";
          }
          if (values.whichWells === "specific wells") {
            if (!values.whichWellsNotes) {
              errors.whichWellsNotes = "Required";
            }
          }
          if (hasLiners) {
            if (!values.wellCleaning) {
              errors.wellCleaning = "Required";
            }
          }

          return errors;
        }}
        onSubmit={(values) => {
          setCheckoutInfo({
            ...checkoutInfo,
            ...values,
          });
        }}
        enableReinitialize={true}
        validateOnMount={true}
      >
        {({ values, touched, errors }) => (
          <Form>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
              <div className="mb-6">
                <label htmlFor="shippingAddress" className="block mb-1">
                  Installation Address
                  <abbr className="required" title="Required">
                    *
                  </abbr>
                </label>
                <Field
                  type="text"
                  name="shippingAddress"
                  placeholder="Installation Address"
                  className="appearance-none border border-grey-light rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:ring h-10"
                />
                <ErrorMessage
                  className="text-red-dark text-sm mt-2"
                  name="shippingAddress"
                  component="div"
                />
              </div>

              <div className="mb-6">
                <label htmlFor="shippingAddress2" className="block mb-1">
                  Installation Address
                </label>
                <Field
                  type="text"
                  name="shippingAddress2"
                  placeholder="Line 2"
                  className="appearance-none border border-grey-light rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:ring h-10"
                />
                <ErrorMessage
                  className="text-red-dark text-sm mt-2"
                  name="shippingAddress2"
                  component="div"
                />
              </div>

              <div className="mb-6">
                <label htmlFor="shippingCity" className="block mb-1">
                  City
                  <abbr className="required" title="Required">
                    *
                  </abbr>
                </label>
                <Field
                  type="text"
                  name="shippingCity"
                  placeholder="City"
                  className="appearance-none border border-grey-light rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:ring h-10"
                />
                <ErrorMessage
                  className="text-red-dark text-sm mt-2"
                  name="shippingCity"
                  component="div"
                />
              </div>

              <div className="mb-6">
                <label htmlFor="shippingState" className="block mb-1">
                  State
                  <abbr className="required" title="Required">
                    *
                  </abbr>
                </label>
                <Field
                  type="text"
                  name="shippingState"
                  placeholder="State"
                  className="bg-white appearance-none border border-grey-light rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:ring"
                  disabled
                />
                <ErrorMessage
                  className="text-red-dark text-sm mt-2"
                  name="shippingState"
                  component="div"
                />
              </div>
            </div>

            <div className="mb-6">
              <RadioButtonGroup
                id="whichWells"
                label=""
                value={values.whichWells}
                error={errors.whichWells}
                touched={touched.whichWells}
                className="flex gap-3"
              >
                <Field
                  component={RadioButton}
                  name="whichWells"
                  id="all wells on home"
                  label="All wells on home"
                  className="block"
                />
                <Field
                  component={RadioButton}
                  name="whichWells"
                  id="specific wells"
                  label="Specific wells"
                  className="block"
                />
              </RadioButtonGroup>
            </div>
            {hasLiners && (
              <div className="mb-4">
                <label>
                  <strong>Well Cleaning:</strong>
                </label>
                <p className="text-xs mb-3">
                  Thank you for choosing the Window Well Liners to enhance your
                  home! We’re excited to serve you and ensure a high-quality
                  installation experience! For the best results, it’s essential
                  that your well is ready for installation—completely cleaned of
                  debris and free of any obstructions, such as lockdowns,
                  ladders, bolts, metal hangers, etc. This allows the liner to
                  adhere properly to the window well.
                </p>
                <p className="text-xs mb-3">
                  If your well isn’t ready on the day of installation, no
                  worries! We’re happy to take care of the cleaning and
                  preparation for you at a rate of $80 per hour (with a minimum
                  charge of 1 hour). This way, we can still deliver the
                  exceptional quality you expect without delay.
                </p>
                <p className="text-xs mb-3">
                  By clicking “I Acknowledge,” you confirm that you understand
                  this requirement and agree to the additional service charge if
                  needed. Thank you for choosing us—we look forward to serving
                  you!
                </p>
                <Field
                  component={Checkbox}
                  name="wellCleaning"
                  id="wellCleaning"
                  label="I Acknowledge"
                  className="block"
                  checked={values.wellCleaning}
                />
              </div>
            )}

            <div className="mb-6">
              {values.whichWells !== "specific wells" && (
                <label htmlFor="billingNotes" className="block mb-2">
                  <strong>Comments or Additional Information:</strong> Please
                  include any relevant details for our office staff or
                  technicians.
                </label>
              )}
              {values.whichWells === "specific wells" && (
                <label htmlFor="billingNotes" className="block mb-2">
                  <strong>Comments or Additional Information:</strong> Please
                  describe which wells you’d like covered, and include any
                  relevant details for our office staff or technician.
                </label>
              )}
              <Field
                component="textarea"
                type="textarea"
                name="whichWellsNotes"
                className="appearance-none border border-grey-light rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:ring h-32"
              />
              <ErrorMessage
                className="text-red-dark text-sm mt-2"
                name="whichWellsNotes"
                component="div"
              />
            </div>
          </Form>
        )}
      </Formik>
      <div className="mb-6 flex items-center gap-2">
        <button
          type="button"
          className="bg-grey-dark text-white px-4 py-2 rounded"
          onClick={() => {
            emitGoToTab("checkout");
          }}
        >
          Previous
        </button>
        <button
          type="button"
          onClick={handleSubmit}
          className="flex items-center gap-2 text-white px-4 py-2 no-underline rounded bg-black"
        >
          Next
          <FaArrowRight />
        </button>
      </div>
    </>
  );
};
export default QuoteSummaryInstallation;
